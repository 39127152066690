import React, { useState } from 'react';
import { Margin } from '../../components/margin';
import { PopUpError } from '../../components/popups';
import HandshakeApi from '../../api/handshakeApi';
import Alert from '../../assets/icons/mdi-atencion.svg';
import styles from './login.module.css';

export const Login = () => {
  const [accessCode, setAccessCode] = useState('');
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [disable, setDisable] = useState(false);

  const validateAccessCode = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)
      && inputValue?.length > 0
      && /^[0-9]*$/.test(inputValue)
    ) {
      setAlert(false);
      setAccessCode(inputValue);
    } else {
      setAlert(true);
      setAccessCode('');
    }
  };

  const handleContinue = async () => {
    const data = {
      numberDocument: accessCode,
      typeDocument: 'CC',
      terms: false,
      description: '',
    };
    if (!accessCode || disable) return;
    setDisable(true);
    await HandshakeApi.validateDNI(data)
      .then(async (res) => {
        if (res?.url === '/') {
          localStorage.removeItem('isLogged');
          setOpen(true);
        } else {
          localStorage.setItem('isLogged', true);
        window.location.href = res?.url;
        };
      })
      .catch((err) => {
        localStorage.removeItem('isLogged');
        setOpen(true);
        // eslint-disable-next-line no-console
        console.error('ERROR: ', err);
      });
    setDisable(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.logo} src={'/logo_claro.png'} alt={'logo'}/>
        <Margin bottom={3.6}/>
        <div className={styles['form-container']}>
          <p className={styles['form-label']}>Código de acceso</p>
          <input
            defaultValue={accessCode}
            className={`${styles['form-input']}`}
            placeholder={'Ingrese su código de acceso'}
            style={accessCode?.length > 0
                ? { borderBottom: '1px solid #DD141D', caretColor: '#DD141D' }
                : {}
            }
            onFocus={() => {
              accessCode.length === 0 ? setAlert(true) : setAlert(false);
            }}
            onBlur={() => setAlert(false)}
            onChange={validateAccessCode}
            onKeyPress={(e) => {
              if (!/\d/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
          <div
            className={styles['alert-container']}
            style={alert && loaded ? {} : { display: 'none' }}
          >
            <img
              width={20} height={20}
              src={Alert} alt={'alert'}
              className={styles['alert-icon']}
              onLoad={() => setLoaded(true)}
            />
            <p className={styles['alert-paragraph']}>
              Su código de acceso es numérico y corresponde a su DNI.
            </p>
          </div>
        </div>
        <Margin bottom={alert ? 4 : 8}/>
        <div className={styles['button-container']}>
          <button
            className={`${styles['continue-button']} ${accessCode?.length > 0 ? styles.send : ''}`}
            onClick={handleContinue}
            style={disable ? { backgroundColor: '#DDDDDD', border: '1px solid #DDDDDD' } : {}}
          >
            Continuar
          </button>
        </div>
        <Margin bottom={5.7}/>
        <p className={styles.notice}>
          Recuerde que al terminar una consulta debe finalizar la sesión presionando
          el botón azul que se encuentra en la esquina superior derecha del chat.
        </p>
      </div>
      {open && <PopUpError open={open} setOpen={setOpen}/>}
    </div>
  );
};
